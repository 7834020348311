var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('vuexy-logo'),_c('h2',{staticClass:"brand-text text-primary ml-1"},[_vm._v(" Vuexy ")])],1),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Forgot password V2"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" Forgot Password? 🔒 ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Enter your email and we'll send you instructions to reset your password ")]),(_vm.resetPasswordStep === 'sendEmail')?_c('validation-observer',{ref:"forgotPassword"},[_c('b-form',{staticClass:"auth-forgot-password-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.sendEmail.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"forgot-password-email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"forgot-password-email","state":errors.length > 0 ? false : null,"name":"forgot-password-email","placeholder":"john@example.com"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,537517673)})],1),_c('b-overlay',{staticClass:"d-block mt-2",attrs:{"show":_vm.sendCodeLoading,"rounded":"","opacity":"0.6","spinner-small":"","spinner-variant":"danger"}},[_c('b-button',{attrs:{"type":"submit","disabled":_vm.sendCodeLoading,"variant":"danger","block":""}},[_vm._v(" send ")])],1)],1)],1):(_vm.resetPasswordStep === 'verifyCode')?_c('validation-observer',{ref:"verifyCode"},[_c('b-form',{staticClass:"auth-reset-password-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.checkVerifyCode.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Code","label-for":"forgot-password-code"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"forgot-password-code","state":errors.length > 0 ? false : null,"name":"forgot-password-code","placeholder":"············"},model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-button',{attrs:{"block":"","type":"submit","variant":"danger"}},[_vm._v(" submit ")])],1)],1):(_vm.resetPasswordStep === 'resetPassword')?_c('validation-observer',{ref:"resetPassword"},[_c('b-form',{staticClass:"auth-reset-password-form mt-2",attrs:{"method":"POST"},on:{"submit":function($event){$event.preventDefault();return _vm.resetPassword.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"New Password","label-for":"reset-password-new"}},[_c('validation-provider',{attrs:{"name":"Password","vid":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"reset-password-new","type":_vm.showPass ? 'text' : 'password',"state":errors.length > 0 ? false : null,"name":"reset-password-new","placeholder":"············"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.showPass ? 'EyeOffIcon' : 'EyeIcon'},on:{"click":function($event){_vm.showPass = !_vm.showPass}}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"reset-password-confirm","label":"Confirm Password"}},[_c('validation-provider',{attrs:{"name":"Confirm Password","rules":"required|confirmed:Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"reset-password-confirm","type":_vm.showPassConfig ? 'text' : 'password',"state":errors.length > 0 ? false : null,"name":"reset-password-confirm","placeholder":"············"},model:{value:(_vm.form.password_confirmation),callback:function ($$v) {_vm.$set(_vm.form, "password_confirmation", $$v)},expression:"form.password_confirmation"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.showPassConfig ? 'EyeOffIcon' : 'EyeIcon'},on:{"click":function($event){_vm.showPassConfig = !_vm.showPassConfig}}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-button',{attrs:{"block":"","type":"submit","variant":"danger"}},[_vm._v(" submit ")])],1)],1):_vm._e(),_c('p',{staticClass:"text-center mt-1"},[_c('b-link',{staticClass:"text-danger",attrs:{"to":{ name: 'auth-login' }}},[_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon"}}),_vm._v(" Back to login ")],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }