<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />

        <h2 class="brand-text text-primary ml-1">
          Vuexy
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img
            fluid
            :src="imgUrl"
            alt="Forgot password V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1">
            Forgot Password? 🔒
          </b-card-title>
          <b-card-text class="mb-2">
            Enter your email and we'll send you instructions to reset your
            password
          </b-card-text>

          <!-- forgot password -->
          <validation-observer
            v-if="resetPasswordStep === 'sendEmail'"
            ref="forgotPassword"
          >
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="sendEmail"
            >
              <b-form-group
                label="Email"
                label-for="forgot-password-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="forgot-password-email"
                    v-model="form.email"
                    :state="errors.length > 0 ? false : null"
                    name="forgot-password-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-overlay
                :show="sendCodeLoading"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="danger"
                class="d-block mt-2"
              >
                <b-button
                  type="submit"
                  :disabled="sendCodeLoading"
                  variant="danger"
                  block
                >
                  send
                </b-button>
              </b-overlay>
            </b-form>
          </validation-observer>

          <!-- verify code -->
          <validation-observer
            v-else-if="resetPasswordStep === 'verifyCode'"
            ref="verifyCode"
          >
            <b-form
              class="auth-reset-password-form mt-2"
              @submit.prevent="checkVerifyCode"
            >
              <!-- password -->
              <b-form-group
                label="Code"
                label-for="forgot-password-code"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Code"
                >
                  <b-form-input
                    id="forgot-password-code"
                    v-model="form.code"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    name="forgot-password-code"
                    placeholder="············"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit button -->
              <b-button
                block
                type="submit"
                variant="danger"
              >
                submit
              </b-button>
            </b-form>
          </validation-observer>
          <!-- reset password -->
          <validation-observer
            v-else-if="resetPasswordStep === 'resetPassword'"
            ref="resetPassword"
          >
            <b-form
              method="POST"
              class="auth-reset-password-form mt-2"
              @submit.prevent="resetPassword"
            >
              <!-- password -->
              <b-form-group
                label="New Password"
                label-for="reset-password-new"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="reset-password-new"
                      v-model="form.password"
                      :type="showPass ? 'text' : 'password'"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      name="reset-password-new"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="showPass ? 'EyeOffIcon' : 'EyeIcon'"
                        @click="showPass = !showPass"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- confirm password -->
              <b-form-group
                label-for="reset-password-confirm"
                label="Confirm Password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Confirm Password"
                  rules="required|confirmed:Password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="reset-password-confirm"
                      v-model="form.password_confirmation"
                      :type="showPassConfig ? 'text' : 'password'"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false : null"
                      name="reset-password-confirm"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="showPassConfig ? 'EyeOffIcon' : 'EyeIcon'"
                        @click="showPassConfig = !showPassConfig"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit button -->
              <b-button
                block
                type="submit"
                variant="danger"
              >
                submit
              </b-button>
            </b-form>
          </validation-observer>
          <p class="text-center mt-1">
            <b-link
              :to="{ name: 'auth-login' }"
              class="text-danger"
            >
              <feather-icon icon="ChevronLeftIcon" /> Back to login
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
    BRow,
    BCol,
    BLink,
    BInputGroup,
    BCardTitle,
    BCardText,
    BInputGroupAppend,
    BImg,
    BOverlay,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import store from '@/store/index'
import AuthRepository from '@/abstraction/repository/authRepository'

const authRepository = new AuthRepository()
export default {
    components: {
        VuexyLogo,
        BRow,
        BCol,
        BLink,
        BImg,
        BInputGroup,
        BInputGroupAppend,
        BForm,
        BOverlay,
        BButton,
        BFormGroup,
        BFormInput,
        BCardTitle,
        BCardText,
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            form: {},
            resetPasswordStep: 'sendEmail',
            sendCodeLoading: false,
            veryCodeLoading: false,
            changePassLoading: false,
            changePasswordLoading: false,
            sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
            // Toggle Password
            showPass: false,
            showPassConfig: false,
            // validation
            required,
            email,
        }
    },
    computed: {
        imgUrl() {
            if (store.state.appConfig.layout.skin === 'dark') {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
                return this.sideImg
            }
            return this.sideImg
        },
    },
    methods: {
        sendEmail() {
            this.$refs.forgotPassword.validate().then(async success => {
                if (success) {
                    await authRepository.forgotPassword(this.form)
                    this.resetPasswordStep = 'verifyCode'
                    // this.$router.push({ name: "auth-reset-password-v1" });
                }
            })
        },
        async checkVerifyCode() {
            this.$refs.verifyCode.validate().then(async success => {
                if (success) {
                    try {
                        this.veryCodeLoading = true
                        await authRepository.forgotPasswordVerify(this.form)
                        this.resetPasswordStep = 'resetPassword'
                    } catch (e) {
                        return e
                    } finally {
                        console.log(this.resetPasswordStep, this.form)
                        this.veryCodeLoading = false
                    }
                }
            })
        },
        async resetPassword() {
            this.$refs.resetPassword.validate().then(async success => {
                if (success) {
                    try {
                        this.changePassLoading = true
                        await authRepository.resetPassword(this.form)
                        this.$router.push({
                            name: 'dashboard',
                        })
                    } catch (e) {
                        return e
                    } finally {
                        this.changePassLoading = false
                    }
                }
            })
        },
    },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
